<template>
  <figure class="table-figure">
    <img class="border rounded" :src="getImage()" height="50" />
  </figure>
</template>

<script>
import get from "lodash/get";
export default {
  name: "ProductTableVariantImage",

  methods: {
    getImage() {
      const variantImage = get(this.data, `image.image_thumb`);
      if (variantImage) {
        return variantImage;
      }
      const productImage = get(this.data, `productproducer.image.image_thumb`);
      if (productImage) {
        return productImage;
      }

      return "https://placehold.co/100x75?text=\u221e";
    },
  },
};
</script>
